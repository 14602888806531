<template>
  <div class="Client">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0">
        <banner
          title="professional payment"
          :breadcrumb="[{ label: 'superadmin' }, { label: 'Payments' }]"
        >
        </banner>
        <div v-if="loading == true">
          <Spinner />
        </div>

        <div class="content-body" style="margin-top: -50px">
          <!-- users list start -->
          <section>
            <div class="row" style="margin-top: -20px">
              <div class="col-md-12 col-sm-12 col-xs-12">
                <div
                  class="card custom-card "
           
                >



                <div class="card-body">
                <div class="row">
                  <div class="col text font">
                    <h4 class="text ">
                        <strong>Professional Payment</strong>
                      </h4>
                  </div>
                  <!-- <div class="col text-end ">
                    <button type="button" @click="loadFormNull()" data-bs-toggle="modal"
                      data-bs-target="#masterDistributerModal" title="Add New" style="background-color: #f21000"
                      class="btn text-white btn-sm">+ New</button>
                    &nbsp;<button type="button" data-bs-toggle="modal" data-bs-target="#new-task-modal" title="Filter"
                      class="btn text-white btn-sm" style="background-color: #00364f"> <strong> <font-awesome-icon
                          icon="filter" /></strong></button>

                  </div> -->
                  <div class="col-xl-1 col-md-1 d-md-block d-xl-block d-none cus-p-1" style="margin-top:-5px">

                    <select style="height: 30px; padding: 5px 5px 5px 5px" class="form-select" @change="changePageNo"
                      aria-label="Default select example">

                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>



                <div class="row">
                  <div class="d-md-none d-xl-none d-block col-3 text font">

                    <select style="height: 30px; padding: 5px 5px 5px 5px" class="form-select" @change="changePageNo"
                      aria-label="Default select example">
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>

                    </select>
                  </div>

                </div>



                <div class="row " style="margin-top:5px">
                    <div
                      class="col-md-12 col-xl-12 col-sm-12"
        
                    >
                     
                      <div class="container-fluid table-scroll">
                        <table class="table table-hover table-sm" style="font-size: 14px;">
                          <tr
                            class="text tr-head table-wrapper-scroll-y rounded-circle"
                            style="background-color: white"
                          >
                            <th
                              class="text-truncate"
                              style="
                                background-color: #cdcdcd;
                                border-radius: 8px 0px 0px 8px;
                              "
                            >
                              +
                            </th>
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              User
                            </th>
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Amount
                            </th>
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Ac No.
                            </th>

                            <th class="text-truncate" style="background-color: #cdcdcd">
                              UPI
                            </th>
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Date
                            </th>
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Payment Mode
                            </th>
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Status
                            </th>

                            <th
                              style="
                                background-color: #cdcdcd;
                                border-radius: 0px 8px 8px 0px;
                              "
                              class="text-truncate text-end"
                            >
                              Action
                            </th>
                          </tr>
                          <tbody class="text">
                            <tr
                              v-for="(walletWithdrawReq, index) in walletwithdrawalsReq"
                              :key="index"
                            >
                              <td>
                                <img
                                  style="width: 8px; margin-right: 5px"
                                  src="/assets/image/record.png"
                                  alt=""
                                />
                              </td>
                              <td class="text-truncate" v-if="walletWithdrawReq">
                             
                                <sapn v-if="walletWithdrawReq.fromuser"> <strong>{{ walletWithdrawReq.fromuser.orgnization }}</strong>   <small>     <div> {{ walletWithdrawReq.fromuser.code }} ( {{ walletWithdrawReq.fromuser.name }} )</div> </small></sapn>
                              
                           </td>
                              <td class="text-truncate" v-if="walletWithdrawReq">
                                {{ walletWithdrawReq.amount }}
                              </td>
                              <td class="text-truncate">
                                <span v-if="walletWithdrawReq.wallet_withdrawal">
                                  <span v-if="walletWithdrawReq.wallet_withdrawal.beneficiaryaccount">
                                    <!-- <div>Name: {{
                                    walletWithdrawReq.wallet_withdrawal.beneficiaryaccount.account_holder_name
                                  }} </div>
                                    <div>Bank:  {{
                                    walletWithdrawReq.wallet_withdrawal.beneficiaryaccount.bank_name
                                  }} </div> -->
                                    <!-- <div> IFSC:  {{
                                    walletWithdrawReq.wallet_withdrawal.beneficiaryaccount.ifsc_code
                                  }}</div>
                                    <div>Ac No: {{
                                    walletWithdrawReq.wallet_withdrawal.beneficiaryaccount.account_number
                                  }}</div> -->
                                    
                                  {{
                                    walletWithdrawReq.wallet_withdrawal.beneficiaryaccount.account_number
                                  }}
                                 
                                  
                                </span>
                                <span v-else> NA </span>
                                </span>

                                <span v-else> NA </span>
                              </td>
                              <td class="text-truncate">
                                <span v-if="walletWithdrawReq.merchant_vpa">
                                  {{ walletWithdrawReq.merchant_vpa }}
                                </span>
                                <span v-else> NA </span>
                              </td>
                              <td class="text-truncate" v-if="walletWithdrawReq">
                                {{ dateTime(walletWithdrawReq.date) }}
                              </td>
                              <td class="text-truncate" v-if="walletWithdrawReq">
                                {{ walletWithdrawReq.paymentMode }}
                              </td>

                              <td class="text-truncate" v-if="walletWithdrawReq">
                                <p
                                  v-if="walletWithdrawReq.status == 'complete'"
                                  class="text-white btn btn-sm"
                                  style="
                                    background-color: green;
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b> {{ walletWithdrawReq.status }}</b>
                                </p>
                                <p
                                  v-if="walletWithdrawReq.status == 'cancel'"
                                  class="text-white btn btn-sm"
                                  style="
                                    background-color: #f21000;
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b> {{ walletWithdrawReq.status }}</b>
                                </p>

                                <p
                                  v-if="walletWithdrawReq.status == 'pending'"
                                  class="text-white btn btn-sm"
                                  style="
                                    background-color: #fc7f03;
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b> {{ walletWithdrawReq.status }}</b>
                                </p>
                                <p
                                  v-if="walletWithdrawReq.status == 'reject'"
                                  class="text-white btn btn-sm"
                                  style="
                                    background-color: black;
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b> {{ walletWithdrawReq.status }}</b>
                                </p>
                              </td>
                              <td class="text-truncate text-end">
                                <div class="btn-group btn-group-sm" role="group">
                                  <router-link
                                    title="View"
                                    :to="`/super-admin/client-invoice-view/${walletWithdrawReq.id}`"
                                    class="btn btns btn-sm text-white"
                                    style="
                                      padding: 5px 4.5px 5px 4.5px;
                                      background-color: #00364f;
                                    "
                                  >
                                    <font-awesome-icon icon="eye" />
                                  </router-link>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>


              </div>


             
              <div class="card-footer">
                <div class="row">

                  <div class="col-md-4 col-12 text-md-start text-center cus-p-1">

                    <p class="mb-0">
                      Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} entries
                    </p>
                  </div>
                  <div class="col-md-8 col-12 text-md-end text-center cus-p-1">
                    <ul class="pagination  justify-content-center">
                      <li class="page-item" @click="superadminWalletWithdrawalGet(null, 'first')">
                        <a class="page-link" href="#" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                        </a>
                      </li>
                      <li class="page-item" @click="superadminWalletWithdrawalGet(null, 'prev')">
                        <a class="page-link" href="#">Previous</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 3"
                        @click="superadminWalletWithdrawalGet(pagination.current_page - 3)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 3
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 2"
                        @click="superadminWalletWithdrawalGet(pagination.current_page - 2)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 2
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 1"
                        @click="superadminWalletWithdrawalGet(pagination.current_page - 1)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 1
                        }}</a>
                      </li>
                      <li class="active page-item" @click="superadminWalletWithdrawalGet(pagination.current_page)">
                        <a class="page-link" href="#">{{ pagination.current_page }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 4"
                        @click="superadminWalletWithdrawalGet(pagination.current_page + 1)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 1
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 5"
                        @click="superadminWalletWithdrawalGet(pagination.current_page + 2)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 2
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 6"
                        @click="superadminWalletWithdrawalGet(pagination.current_page + 3)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 3
                        }}</a>
                      </li>
                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="superadminWalletWithdrawalGet( pagination.current_page + 4 )"><a href="#">{{pagination.current_page + 4}}</a></li> -->
                      <li class="page-item">
                        <a class="page-link" href="#">...</a>
                      </li>

                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="superadminWalletWithdrawalGet( pagination.last_page - 4 )"><a href="#">{{pagination.last_page - 4}}</a></li> -->
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 3"
                        @click="superadminWalletWithdrawalGet(pagination.last_page - 3)">
                        <a class="page-link" href="#">{{ pagination.last_page - 3 }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 2"
                        @click="superadminWalletWithdrawalGet(pagination.last_page - 2)">
                        <a class="page-link" href="#">{{ pagination.last_page - 2 }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 1"
                        @click="superadminWalletWithdrawalGet(pagination.last_page - 1)">
                        <a class="page-link" href="#">{{ pagination.last_page - 1 }}</a>
                      </li>

                      <li class="page-item" @click="superadminWalletWithdrawalGet(null, 'next')">
                        <a class="page-link" href="#">Next</a>
                      </li>
                      <li class="page-item" @click="superadminWalletWithdrawalGet(null, 'last')">
                        <a class="page-link" href="#">&raquo;</a>
                      </li>
                    </ul>
                  </div>

                  <!-- <div class="col-md-1 col-4 text-end">
      <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
         
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
    </div> -->
                </div>
              </div>
                </div>
              </div>
            </div>
          </section>
          <!-- users list ends -->
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>
</template>

<script>
import moment from "moment";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Banner from "../../../components/super-admin/comman/Banner.vue";
import Spinner from "../../../components/super-admin/comman/Spinner.vue";
// import Form from "vform";
// import jQuery from "jquery";
// let $ = jQuery;
export default {
  name: "Ticket",
  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      pagination: {
        current_page: 0,
        from: 0,
        to: 0,
        total: 0,
        last_page: 0,
        per_page: 0,
        first_page_url: null,
        last_page_url: null,
        next_page_url: null,
        prev_page_url: null,
        path: null,
      },
      per_page:10,
      loading: false,
      withdrawalEdit: "false",
      walletEdit: "false",
      walletwithdrawalsReq: [],
      wallet: {},
      walletwithdrawalForm: {
        wallet_id: null,
        amount: null,
        paymentMode: "",
        beneficiaryaccount_id: null,
        beneficiaryaccount_type: "",
        merchant_vpa: "",
        remark: "",
        errors: {},
      },
    };
  },
  methods: {
    changePageNo(e) {
      this.per_page = e.target.value;
      this.superadminWalletWithdrawalGet();
    },
    walletWithdrawReqApproved(value) {
      console.log(value);

      console.log(value.user_type);
      var form = {};

      if (value.paymentMode == "upi") {
        form = {
          paymentMode: value.paymentMode,
          amount: value.amount,
          merchant_vpa: value.merchant_vpa,
          beneAccNo: value.merchant_vpa,
          beneIFSC: value.merchant_vpa,
          beneName: value.merchant_vpa,
        };
      }
      if (value.paymentMode == "imps" || value.paymentMode == "neft") {
        form = {
          paymentMode: value.paymentMode,
          amount: value.amount,
          beneAccNo: value.beneficiaryaccount.account_number,
          beneIFSC: value.beneficiaryaccount.ifsc_code,
          beneName: value.beneficiaryaccount.account_holder_name,
        };
      }
      if (value.paymentMode == "irgs") {
        form = {
          paymentMode: value.paymentMode,
          amount: value.amount,
          beneAccNo: value.beneficiaryaccount.account_number,
          beneIFSC: value.beneficiaryaccount.ifsc_code,
          beneName: value.beneficiaryaccount.account_holder_name,
        };
      }

      console.log(form);
      this.loading = true;
      this.$axios
        .post(`superadmin/walletwithdrawal/approve/${value.id}`, form, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data);

          toast.success("Request Approved  successfully", {
            autoClose: 1000,
          });

          this.superadminWalletWithdrawalGet();
        })

        .finally(() => (this.loading = false));
    },
    walletWithdrawReqCancel(value) {
      console.log(value);
      console.log(value.user_type);
      this.loading = true;
      this.$axios
        .post(
          `superadmin/walletwithdrawal/reject/${value.id}`,
          {},
          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          console.log(res.data);

          toast.success("Request Canceled  successfully", {
            autoClose: 1000,
          });

          this.superadminWalletWithdrawalGet();
        })

        .finally(() => (this.loading = false));
    },
    superadminWalletWithdrawalGet(page = null, pg = null) {
      console.log(page);
      console.log(pg);
      this.loading = true;
      var pageUrl =
        "superadmin/transaction?fromuser_type=professional&touser_type=professional";
      if (this.pagination.last_page_url && pg == "last") {
        pageUrl = pageUrl + this.pagination.last_page_url;
      } else if (this.pagination.first_page_url && pg == "first") {
        pageUrl = pageUrl + this.pagination.first_page_url;
      } else if (this.pagination.prev_page_url && pg == "prev") {
        pageUrl = pageUrl + this.pagination.prev_page_url;
      } else if (this.pagination.next_page_url && pg == "next") {
        pageUrl = pageUrl + this.pagination.next_page_url;
      }

      if (pg == null) {
        pageUrl += `superadmin/transaction?fromuser_type=professional&touser_type=professional&per_page=${this.per_page}`;
      } else {
        pageUrl += `fromuser_type=professional&touser_type=professional&per_page=${this.pagination.per_page}`;
      }
      if (this.table_search) {
        pageUrl += `&searchTerm=${this.table_search}`;
      }
      if (page != null) {
        pageUrl += `&page=${page}`;
      }

      this.$axios
        .get(pageUrl, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.walletwithdrawalsReq = res.data.data.data;
          this.pagination = res.data.data;
        })
        .finally(() => (this.loading = false));
    },
    deletePackage(id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be Delete it",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00364f",
          cancelButtonColor: "#f21000",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$axios
              .delete(`superadmin/clientserviceticket/${id}`, {
                headers: { Authorization: "Bearer " + localStorage.accessToken },
              })
              .then((res) => {
                res;
                toast.success("Ticket Deleted  Succesfully", {
                  autoClose: 1000,
                });
                this.loadTicket();
              })
              .catch((error) => {
                error;
              });
          }
        });
    },
    dateTime(value) {
      return moment(value).format("DD-MM-YYYY");
    },
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.superadminWalletWithdrawalGet();
    }
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}
@media screen and (max-width: 767px) {
  .mt-top-numbers {
    margin-top: -26px;
  }
}

.h1-heading {
  color: red;
  font-size: 3vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.btns {
  display: inline-flex;
}
.page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #00364f;
  color: #fff !important;
  font-weight: 600;
}
.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
.page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #00364f;
  color: #fff !important;
  font-weight: 600;
}
h4[data-v-69e5a8da] {
  color: #fff;
  font-family: poppins, Sans-serif;
  font-weight: 600;
  font-size: 25;
}




.cus-p-1 {
  padding: 5px 5px 5px 5px;
}

@media only screen and (min-width: 600px) {
  .justify-content-center {
    justify-content: end !important;
  }
}

.card-footer {
  padding: 0px 10px 0px 10px;
}
</style>
